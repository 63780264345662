/* Franklin Gothic fonts */
@font-face {
  font-family: 'Franklin Gothic FontD';
  src: url('./FranklinGothic/FranklinGothic-Demi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic FontB';
  src: url('./FranklinGothic/FranklinGothic-Book-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic FontM';
  src: url('./FranklinGothic/FranklinGothic-Medium-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
